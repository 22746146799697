/* eslint-disable */
  import config from "config";
  const getData = async(fetchData, dispatch, targetNode,setChartData) => {
  const todayDate = new Date()
  const formattedCurrentMonth = parseInt(todayDate.getMonth()) >= 9 ? parseInt(todayDate.getMonth() + 1)  : "0"+ parseInt(todayDate.getMonth() + 1)
  const formattedCurrentDate =  todayDate.getDate() >= 10 ? todayDate.getDate() : "0"+todayDate.getDate()
  const currentDate = formattedCurrentDate+"-"+ formattedCurrentMonth +"-" + todayDate.getFullYear()
 
  const pastMonthDate = new Date()
  pastMonthDate.setMonth(pastMonthDate.getMonth() - 2)
  const formattedPastMonth = parseInt(pastMonthDate.getMonth()) >= 9 ? parseInt(pastMonthDate.getMonth()+1) : "0"+parseInt(pastMonthDate.getMonth()+ 1) 
  const formattedPastDate =  pastMonthDate.getDate() >= 10 ? pastMonthDate.getDate() : "0"+pastMonthDate.getDate()
  const pastDate = formattedPastDate+"-"+formattedPastMonth+"-"+pastMonthDate.getFullYear()
  try{
    const data =  await fetchData(config.CORS_URL+'getEquityEODPriceWithSymbol?equity='+targetNode+'&trade_date='+pastDate+','+currentDate)
    dispatch(setChartData(data));
  }
  catch(error)
  {
    console.error("Error",error)
  }
}

export const getIndexData = async (fetchData, dispatch, setChartIndexData, popoverIndexLabels) => {
  const todayDate = new Date()
  const formattedCurrentMonth = parseInt(todayDate.getMonth()) >= 9 ? parseInt(todayDate.getMonth() + 1)  : "0"+ parseInt(todayDate.getMonth() + 1)
  const formattedCurrentDate =  todayDate.getDate() >= 10 ? todayDate.getDate() : "0"+todayDate.getDate()
  const currentDate = formattedCurrentDate+"-"+ formattedCurrentMonth +"-" + todayDate.getFullYear()
 
  const pastMonthDate = new Date()
  pastMonthDate.setMonth(pastMonthDate.getMonth() - 2)
  const formattedPastMonth = parseInt(pastMonthDate.getMonth()) >= 9 ? parseInt(pastMonthDate.getMonth()+1) : "0"+parseInt(pastMonthDate.getMonth()+ 1) 
  const formattedPastDate =  pastMonthDate.getDate() >= 10 ? pastMonthDate.getDate() : "0"+pastMonthDate.getDate()
  const pastDate = formattedPastDate+"-"+formattedPastMonth+"-"+pastMonthDate.getFullYear()
  
  try {
    const indexDataPromises = popoverIndexLabels.map(async (indexName) => {
      try {
        const data = await fetchData(
          `${config.CORS_URL}getIndexEODPrice?trade_date=${pastDate},${currentDate}&index_name=${encodeURIComponent(indexName)}`
        );
        return { indexName, data }; // `data` contains response JSON
      } catch (error) {
        console.error(`Error fetching data for ${indexName}:`, error);
        return null;
      }
    });

    const results = await Promise.all(indexDataPromises);
    const filteredResults = results.filter((result) => result !== null);
    
    const updatedData = filteredResults.map((result) => ({
      indexName: result.indexName,
      data: result.data, // Ensure data is correctly assigned
    }));

    dispatch(setChartIndexData(updatedData));
  } catch (error) {
    console.error("Error processing index data:", error);
  }
}

export default getData