import { apiConfig } from "apiConfig";
const getAvailableMargin = async (fetchData, clientId,accountId, dispatch, setAvailableMargin) => {
    try{
        const data = await fetchData(`${apiConfig.THOR_URL}margins?client_id=${clientId}&account_id=${accountId}`);
        dispatch(setAvailableMargin(data?.availableCash));
    }
    catch(error){
        console.error("Error",error);
    }    
      
}

export default getAvailableMargin