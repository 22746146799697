/* eslint-disable */

import { useSelector } from "react-redux";
import {Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from './layouts/Admin.js';
import DerivativeGraphTempalte from "components/DerivativeGraph/container/DerivativeGraphTemplate"
import HomePage from "components/HomePage/HomePage.jsx";
import ProtectedRoute from "ProtectedRoute.js";
import { apiConfig } from "apiConfig.js";
import VerifyEmailTemplate from "components/VerifyEmail/VerifyEmailTemplate.jsx";
const App = () => {

  const origin = window.location.origin;
  const isInternalIP = /^http:\/\/\d{3}\.\d{3}\.\d{2}\.\d{1,2}(:\d+)?$/.test(origin);
    
    const element = useSelector(state => state.graphTemplate.elementCopy);
    return(
        <Routes>
          <Route path="/home" element={<HomePage/>} />
          {
            isInternalIP? 
            <Route path="/" element={<Navigate to="/default/dashboard" replace />} />:
            <Route path="/" element={<Navigate to="/home" replace />} />
          }
          <Route path="/default/*" 
          element={
              <ProtectedRoute>
                <AdminLayout />
              </ProtectedRoute>
            } 
          />
          <Route path="/verifyEmail" element={
            <ProtectedRoute>
              <VerifyEmailTemplate/>
            </ProtectedRoute>
          }/>

          <Route path="/default/derivative/:equityName" 
            element = {
              <ProtectedRoute>
                <DerivativeGraphTempalte elements = {element}/>
              </ProtectedRoute>
            }/>
        </Routes> 
    )
}

export default App