/*eslint-disable*/
import React,{useState} from "react";

import TradesGrid from "../components/PositionsGrid/container/PositionsGrid";
import TradesFilter from "../components/TradesFilter/container/TradesFilter";
import store from "../app/store";

// reactstrap components
import {
  UncontrolledAlert,
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import PositionActions from "components/PositionsGrid/presentation/PositionActions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { saveOrderDetails } from "components/PositionsGrid/container/PositionActionSlice";
import Toasts from "components/Toasts/Toasts";
function Notifications() {

  const dispatch = useDispatch();


  useEffect(()=>{

    //to clear positions order form cache
    return ()=>{
      dispatch(saveOrderDetails(null));
    }
  })
 
 return (
  <>
    <Row>
      <Col md="auto">
        <Card>
          <TradesGrid />
        </Card>
        <div>
          <PositionActions />
        </div>
      </Col>
    </Row>
    <Toasts />      
  </> 
  
 );
}

export default Notifications;
