/* eslint-disable */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFutureEODPrice, getOptionEODPrice } from "./cypherResult";
import ChartPage from "../../Chart/container/ChartPage";
import getData, { getIndexData } from "../../Chart/container/CypherResult";
import { useState } from "react";
import { getEODPrice } from "./cypherResult";
import { setChartIndexData, setShowChart } from "../../DerivativeGraph/container/DerivativeGraphSlice";
import { setChartData } from "../../DerivativeGraph/container/DerivativeGraphSlice";
import { useFetchData } from "utils/customFetch";

const DerivativeChartTemplate = () => {
    const dispatch = useDispatch();
    const { fetchData } = useFetchData();
    const [data, setData ] = useState([])
    const [yAxisData, setYAxisData] = useState([])
    const popoverNode = useSelector(state => state.derivativeGraphTemplate.popoverNode)
    const chartTitle = useSelector(state => state.derivativeGraphTemplate.chartTitle)
    const chartData = useSelector(state => state.derivativeGraphTemplate.chartData)
    const chartIndexData = useSelector(state => state.derivativeGraphTemplate.chartIndexData)
    const popoverIndexLabels = useSelector(state => state.mainTemplate.popoverIndexLabels)
    const derivativeNodeZenID = useSelector(state => state.derivativeGraphTemplate.derivativeNodeZenID)
    const isIndex = useSelector(state => state.derivativeGraphTemplate.isIndex)
    const isDerivative = useSelector(state => state.derivativeGraphTemplate.isDerivative)

    document.addEventListener('keydown', function(event){
      if(event.key === 'Escape')
      {
        dispatch(setShowChart(false))

      }
    })
    useEffect(() => {
      dispatch(setChartData([]))
      dispatch(setChartIndexData([]))
    },[])
    const options = {
        title : {
          text : chartTitle
        },
        xAxis : {
          // type : 'datetime',
          title : {
            text : 'Trade Date'
          }
        },
        yAxis :yAxisData,
        series: data,        
        credits: {
          enabled: false
        },
        legend: {
          enabled: true,
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'middle'
      }
        
    };
    
    useEffect(() => {
      if(!isIndex)
      {
        if(popoverNode.data().instrument_type === 'PE' || popoverNode.data().instrument_type === 'CE')
          getOptionEODPrice(derivativeNodeZenID,dispatch, setChartData)
        else if(popoverNode.data().instrument_type === 'FUT')
          getFutureEODPrice(derivativeNodeZenID, dispatch, setChartData)
        else
          getData(fetchData, dispatch, popoverNode.data().properties.name, setChartData)
      }
        getIndexData(fetchData, dispatch,setChartIndexData, popoverIndexLabels )        
    },[derivativeNodeZenID])

    useEffect(() => {
      if(!isIndex && Object.keys(chartData).length !=0 && Object.keys(chartIndexData).length!=0 )
        getEODPrice(chartTitle, popoverIndexLabels, chartData, chartIndexData, setData, isIndex, setYAxisData, isDerivative)
      else if(isIndex && Object.keys(chartIndexData).length!=0)
        getEODPrice(chartTitle, popoverIndexLabels, chartData, chartIndexData, setData, isIndex, setYAxisData, isDerivative)
    },[chartData, chartIndexData])
    return (
        <ChartPage
            targetNode={chartTitle}
            options={options}
            data={data}
        />
    )
}

export default DerivativeChartTemplate;