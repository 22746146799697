import React from 'react'
import fetchBetaValues from './getPortfoliobeta'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGlobalState } from 'components/globalVariable'
import { useDispatch } from 'react-redux'
import { useFetchData } from 'utils/customFetch'

export default function BetaContainer() {
    
    const { fetchData } = useFetchData();
    const betaValue = useSelector(state =>{
        return state.portfolioBeta.portfolioBeta
    })
    const [clientId] = useGlobalState("clientId")
    const [accountIds] = useGlobalState('accountIds')
    const dispatch = useDispatch();
    

    useEffect(()=>{
        fetchBetaValues(fetchData, clientId,accountIds,dispatch)
    },[clientId,accountIds,dispatch, fetchData])

    return (
<span className='text-xs text-black font-semibold'>{betaValue?betaValue:"NA"}</span>
    )
}
