/*eslint-disable*/
import React,{useState} from "react";


// reactstrap components
import {
  UncontrolledAlert,
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import GreeksGrid from "components/GreeksAnalyzer/GreeksGrid";
import { useDispatch, useSelector } from "react-redux";
import { saveSecurity } from "reducers/FilterSlice";
function GreeksAnalyzer() {
  const dispatch = useDispatch();
  dispatch(saveSecurity(""));

 return (
  <Row>
      <Col xs="auto">
        <Card>   
            <GreeksGrid/> 
        </Card>
      </Col>
  </Row>

 );
}

export default GreeksAnalyzer;
