/*eslint-disable*/
import React, {useMemo, useRef, forwardRef, useEffect} from 'react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { useState } from "react";
import "./TradesFilter.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {saveDates,saveSecurity,saveExchange,saveStrategy,saveBroker,saveEntity,savePortfolio} from "reducers/FilterSlice";
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import {
Button,
Input,
Form,
Label,
FormGroup,
Col
} from 'reactstrap';
import './date-style.css';
import { useRealtimeZenSecurities2 } from 'components/getAllSourcesSecurities';
import { setZenSecIds } from 'reducers/FilterSlice';


const TradesFilter = () => {
      const filter = useSelector(state => state.filter);
      const filterDictionary = {
        dates:filter.dates,
        broker: filter.broker,
        security: filter.security,
        exchange: filter.exchange,
        portfolio: filter.portfolio,
        entity: filter.entity,
        strategy: filter.strategy
    };
  
    const [dateRange, setDateRange] = useState([filterDictionary.dates[0],filterDictionary.dates[1]]);
    const [startDate, endDate] = dateRange;

    const inputRef = useRef(null);

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input className="form-control" onClick={onClick} ref={ref}
        value={value}>
      </input>
    ));

    const filterOptions = (options, { inputValue }) => {
      return options.filter(option =>
        option.title.startsWith(inputValue.toUpperCase())
      ).slice(0,3000)
    }

    const dispatch = useDispatch();
    const [securities,setSecurities] = useState([]);
    const [autocompleteValue,setAutoCompleteValue] = useState("");
    const [dummy, setDummy] = useState('dummy');
    const zenSecuritiesData = useRealtimeZenSecurities2();


  
  function sortOptions(options) {
              return options.sort((a, b) => {
                // Extract the option type (PE or CE) from the title
                  const aType = a.title.slice(-2);
                  const bType = b.title.slice(-2);
                
                // Extract the trading symbol without the option type
                  const aSymbol = a.title.slice(0, -2);
                  const bSymbol = b.title.slice(0, -2);
                
                // If the symbols are different, sort by symbol
                  if (aSymbol < bSymbol) return -1;
                  if (aSymbol > bSymbol) return 1;
                
                // If the symbols are the same, prioritize CE over PE
                  if (aType === 'CE' && bType === 'PE') return -1;
                  if (aType === 'PE' && bType === 'CE') return 1;
                
                // If both are same (both PE or both CE), maintain original order
                  return 0;
              });
            }
             
  function filterData(){
          let list = [];
          zenSecuritiesData.then(data => {
            data["EQ"]?.map((item,v)=>{
              list.push({
                title:item.trading_symbol,
                type:"EQ",
                lotSize:item.lot_size,
                zenSecId : item.zen_id,
                index:v
              });
             });

             let list1 = list.sort((a,b)=>a.title-b.title);
             list = [];

             data["CE"]?.map((item,v)=>{
              list.push({
                title:item.trading_symbol,
                type:"OPT",
                lotSize:item.lot_size,
                zenSecId : item.zen_id,
                index:v
              });              
             })

             data["PE"]?.map((item,v)=>{
              list.push({
                title:item.trading_symbol,
                type:"OPT",
                lotSize:item.lot_size,
                zenSecId : item.zen_id,
                index:v
              });
             })

             let list2 = sortOptions(list);
             list = [];

             data["FUT"]?.map((item,v)=>{
              list.push({
                title:item.trading_symbol,
                type:"FUT",
                lotSize:item.lot_size,
                zenSecId : item.zen_id,
                index:v});
             })
             let list3 = list.sort((a,b)=>a.title-b.title)
             
             list = [...list1,...list2,...list3]

          }).then(()=>{
            setSecurities(list);
          })
          
    }

    
    useEffect(()=>{
      filterData();
    },[])

    //adding event listener for selecting text in Securities Autocomplete bar
    //while clicking SHIFT + HOME - select to highlight
    // DEL to delete text
    /*
    const highlightText = () => {
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.focus();
        inputElement.setSelectionRange(0, autocompleteValue.length);
      }
    };
    
  
    useEffect(()=>{

      const handleKeyDown = (event) => {
        if (event.key === 'Home' && event.shiftKey) {
          //event.preventDefault();
          highlightText();
        } else if (event.key === 'Delete') {
          if (document.getSelection().toString() === autocompleteValue) {
            setAutoCompleteValue('')
          }
        }
      };
  
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.addEventListener('keydown', handleKeyDown);
      }
  
      return () => {
        if (inputElement) {
          inputElement.removeEventListener('keydown', handleKeyDown);
        }
      };
    },[inputRef])
    */
    

   
    return (
          <div className='flex space-x-6 font-semibold'>
                
                  <Label >Date
                  <div className='form-control' style={{ height: '43px',cursor:"pointer" }}>
                <DatePicker 
                    selectsRange={true}     
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange([update[0],update[1]]);
                        if (update[1]!==null) dispatch(saveDates([update[0],update[1]]));
                      } 
                    }
                    isClearable={false}
                    className="nomarg w-64"
                    style={
                      {zIndex:100}
                    }
                    placeholderText='Choose Date Range'
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    portalId={"date-picker"}
                    //for external date z index
                  >
                    <Button color="primary" onClick={()=>{
                      const todayDate = new Date()
                      setDateRange([todayDate,todayDate]);
                      dispatch(saveDates([todayDate,todayDate]));  
                    }}>
                      Today
                    </Button>

                    <Button color="primary" onClick={() => {
                      const today = new Date();
                      if (today.getDay() === 1) {
                          today.setDate(today.getDate() - 3);
                      } else {
                          today.setDate(today.getDate() - 1);
                      }
                      setDateRange([today, today]);
                      dispatch(saveDates([today, today]));  
                  }}>
                  Yesterday
                  </Button>
                  </DatePicker>

                  </div>
                  </Label>
                

                
                  <Label>Security
                    <div style={{ width: 'auto',minWidth: '250px' }}> {/* This wrapper will allow the width to expand */}
                      <Autocomplete
                        multiple
                        disablePortal
                        noOptionsText={securities.length ? "No results found" : "Loading..."}
                        inputValue={autocompleteValue}
                        onInputChange={(event, newInputValue) => {
                          setAutoCompleteValue(newInputValue);
                        }}
                        onChange={(e, value) => {
                          dispatch(setZenSecIds(value.length ? value.map(item => item.zenSecId) : []))
                          dispatch(saveSecurity(value.length ? value.map(item => item.title) : ""));
                        }}
                        autoHighlight={true}
                        id="combo-box-demo"
                        options={securities}
                        groupBy={(option) => option.type}
                        getOptionLabel={(option) => option.title}
                        filterOptions={filterOptions}
                        sx={{
                          width: '100%', // Allow full width
                          '& .MuiAutocomplete-inputRoot': { height: 42 },
                          '& .MuiInputBase-root': { padding: "0px 0px 0px 5px" }
                        }}
                        renderInput={(params) => (
                          <TextField
                            inputRef={inputRef}
                            {...params}
                            variant="outlined" // Use outlined variant for better visibility
                            InputProps={{
                              ...params.InputProps,
                              style: { flexWrap: 'wrap' } // Wrap the selected chips
                            }}
                          />
                        )}
                        renderOption={(props, item) => (
                          <span {...props} key={item.title + item.exchange + item.index}>
                            {item.title}
                          </span>
                        )}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value.title} label={value.title} />
                            ))}
                          </Box>
                        )}
                      />
                    </div>
                  </Label>

                
                  {/* <Label>Exchange
                  <Input type="select"
                        className=' w-44' 
                      value={filterDictionary.exchangeFilter}
                      onChange={e=>{
                        dispatch(setExchangeFilter(e.target.value));
                      }}>
                          <option>ALL</option>
                          <option >ZEN_EXCHANGE</option>
                          <option >NSE</option>
                          <option >NFO</option>
                      </Input>
                  </Label> */}
                

                
                  {/* {<Label>Strategy
                  <Input type="select"
                  value={filterDictionary.strategy}
                  disabled={false}
                  onChange={e=>{
                    dispatch(saveStrategy((e.target.value)));
                  }}>
                  <option value={""}>ALL</option>
                  <option value={"1"}>One</option>
                  <option value={"11000"}>2PM_STRATEGY</option>
                  <option value={"11001"}>2PM_STRATEGY_TEST1</option>
                  <option value={"11002"}>2PM_STRATEGY_TEST2</option>
                  </Input>
                  </Label>} */}
                
                  {/* <Label>Portfolio
                  <Input type="select"
                     value={filterDictionary.portfolio}
                     disabled={true}
                    onChange={e=>{
                      console.log(e.target.value)
                      dispatch(savePortfolio(e.target.value))
                    }}
                    ><option>ALL</option>
                    <option>portfolio</option>
                  </Input>
                  </Label>
                
                  <Label>Entity
                  <Input type="select"
                 value={filterDictionary.entity}
                 disabled={true}
                  onChange={e=>{
                  console.log(e.target.value)
                  dispatch(saveEntity(e.target.value))
                  }}>
                        <option>ALL</option>
                        <option>entity</option>
                  </Input>
                  </Label> */}
                
                  {/* {<Label>Broker Account
                  <Input type="select" 
                     value={filterDictionary.broker}
                    onChange={e=>{
                      dispatch(saveBroker(e.target.value))
                    }}>
                        <option>ALL</option>
                        <option>ZEN_BROKER</option>
                        <option>ZERODHA</option>
                        <option>KOTAK</option>
                  </Input>
                  </Label>} */}
                      
          </div>
    )


};

export default TradesFilter;
