
import { createSlice } from '@reduxjs/toolkit';


const ProtectedRouteSlice = createSlice({
    name: 'protectedRoute',
    initialState: {
        accessToken : null,
        isAuthorized : true
    },
    reducers: {
        
        setAccessToken(state,action){
            state.accessToken = action.payload
        },

        setIsAuthorized(state, action){
            state.isAuthorized = action.payload
        }

    },
});

export const { setAccessToken, setIsAuthorized } = ProtectedRouteSlice.actions;

export default ProtectedRouteSlice.reducer;
