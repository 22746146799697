const UnAuthorizedPage = () => {
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center bg-gray-100">
        <div className="bg-white shadow-lg rounded-2xl p-8 max-w-md text-center">
          <h1 className="text-2xl font-bold text-blue-950">Access Denied</h1>
          <p className="text-gray-600 mt-4">
            You don’t have access to this page yet. Please wait while the admin grants you the necessary permissions.  
            We appreciate your patience.
          </p>
          
        </div>
      </div>
    );
  };
  
  export default UnAuthorizedPage;
  