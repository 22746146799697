/*eslint-disable*/
import {Link} from 'react-router-dom';
import placeOrder from '../../../assets/img/place-order.png';
import chart from '../../../assets/img/chart.png';
import derivatives from '../../../assets/img/derivatives.png';
import cancel from '../../../assets/img/cancel.png'


const Popover = (
        {
            showForm,
            showChart,
            showDerivative,
            hideForm,
            listStyle,
            listItemStyle,
            isIndex,
            isSector,
            isDerivative,
            popoverNode,
            imageStyle,
            isFuture,
            isAuthorized
        }
    ) => {
        console.log("Checkign is Authorized : ", isAuthorized)
    return (
        <>
            {
            !isSector && <div className = 'list-group' style={listStyle}>
                {
                    isAuthorized && !isIndex && <div className='list-group-item list-group-item-action' onClick = {(e) => showForm(e) } >
                                    <Link to ='' style={listItemStyle}>
                                        <img src = {placeOrder} style={imageStyle}/>
                                        Place Order
                                    </Link>
                                </div>
                }
                {
                    !isFuture && <div className='list-group-item list-group-item-action' onClick={(e) => showChart(e)}>
                        <Link to ='' style={listItemStyle}>
                            <img src={chart} style={imageStyle}/>
                            Price History
                        </Link>
                    </div>
                }
                {/* {
                    !isDerivative && <div className='list-group-item list-group-item-action' onClick = {showDerivative} >
                                        <Link to ={`/default/derivative/${popoverNode.data().properties.name}`}  style={listItemStyle}>
                                            <img src= {derivatives} style={imageStyle}/>
                                            Show Derivative
                                        </Link>
                                    </div>
                } */}
                <div className='list-group-item list-group-item-action' onClick = {(e) => hideForm(e)}>
                    <Link to ='' style={listItemStyle}><img src= {cancel} style={imageStyle}/>
                        Cancel
                    </Link>
                </div>
            </div>
            }
        </>        
    )
}

export default Popover;