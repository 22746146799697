import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiConfig } from 'apiConfig';

let tokenPromise = null;  // Store the shared promise

const waitForAccessToken = (getState) => {
    if (tokenPromise) return tokenPromise;  // ✅ Return existing promise if already waiting

    tokenPromise = new Promise((resolve) => {
        const checkToken = () => {
            const state = getState();
            const accessToken = state.protectedRoute.accessToken;
            
            if (accessToken) {
                resolve(accessToken);
                tokenPromise = null;  // Reset after resolving
            } else {
                setTimeout(checkToken, 100); 
            }
        };
        checkToken();
    });

    return tokenPromise;
};

export const fetchClients = createAsyncThunk(
  'clientSelection/fetchClients',
  async (_,thunkAPI) => {
    const accessToken = await waitForAccessToken(thunkAPI.getState);

    const response = await fetch(`${apiConfig.THOR_URL}getClients`, {
      headers :{
        "Authorization" : `Bearer ${accessToken}`
      }
    });
    return response.json();
  }
);

export const fetchClientInfo = createAsyncThunk(
  'clientSelection/fetchClientInfo',
  async (clientId, thunkAPI) => {
    
    const accessToken = await waitForAccessToken(thunkAPI.getState);
    const response = await fetch(`${apiConfig.THOR_URL}getClientInfo?clientId=${clientId}`, {
      headers : {
        "Authorization" : `Bearer ${accessToken}`
      }
    });
    return response.json();
  }
);

const initialState = {
  clients: [],
  selectedClient: null,
  clientInfo: null,
  selectedBroker: null,
  selectedBrokerName: null,
  selectedAccount: null,
  selectedStrategy: null,
  status: 'idle',
  error: null
};

const clientSelectionSlice = createSlice({
  name: 'clientSelection',
  initialState,
  reducers: {
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
      state.selectedBroker = null;
      state.selectedAccount = null;
      state.selectedStrategy = null;
    },
    setSelectedBroker: (state, action) => {
      state.selectedBroker = action.payload;
      state.selectedAccount = null;
      state.selectedStrategy = null;

      if (state.clientInfo && state.clientInfo.broker_infos ) {
        // Find the broker by matching broker_id
        const broker = state.clientInfo.broker_infos.find(
          (b) => b.broker_id === action.payload
        );
    
        // Set the selectedBrokerName if the broker is found
        state.selectedBrokerName = broker ? broker.broker_name : null;
      }
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
      state.selectedStrategy = null;
    },
    setSelectedStrategy: (state, action) => {
      state.selectedStrategy = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clients = action.payload;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(fetchClientInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClientInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientInfo = action.payload;
      })
      .addCase(fetchClientInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  }
});

export const { setSelectedClient, setSelectedBroker, setSelectedAccount, setSelectedStrategy } = clientSelectionSlice.actions;

export default clientSelectionSlice.reducer;