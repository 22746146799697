/*eslint-disable*/
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsPnlFetching, setLineChartData, setPnlType } from "./PnlLineChartSlice";
import { useEffect } from 'react';
import { useGlobalState } from "components/globalVariable";
import 'react-datepicker/dist/react-datepicker.css';
import PnlLineChartTemplate from "./PnlLineChartTemplate";
import { apiConfig } from "apiConfig";
import { useFetchData } from "utils/customFetch";

const PnlLineChartComponent = ({dateConversion}) =>{
    const { fetchData } = useFetchData();
    const targetSymbol = useSelector(state => state.pnlLineChart.targetSymbol);
    const type = useSelector(state => state.pnlLineChart.type);
    const zenSecId = useSelector(state => state.pnlLineChart.zenSecId);
    const dateRange = useSelector(state => state.pnlLineChart.dateRange);
    const {selectedStrategy, selectedBroker, selectedBrokerName, selectedAccount} =  useSelector(state => state.clientSelection)
    const [clientId]= useGlobalState("clientId");
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setIsPnlFetching(true));
        const fetchDataCall = async () => {
        // if(targetSymbol==="")return;
        //     dispatch(setIsFetching(true));
            try
            {
                const header = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                };
                const body = {  //  15 (days) * 24 (hours) * 60 (minutes) * 60 (seconds) * 1000 (milliseconds )
                    "startTimestamp": `${dateConversion(new Date(2024,6,1)?.toLocaleDateString() )} 00:00:00`,
                    "endTimestamp":  `${dateConversion(new Date()?.toLocaleDateString())} 23:59:59` ,
                    "positionCompKeyFilter":{
                        "clientId":clientId,
                        ...((selectedStrategy === "all" || selectedStrategy === "" || selectedStrategy == null )  ? {  } : { strategyIds : [selectedStrategy]}),
                        ...((selectedBroker === "all" || selectedBroker === "" || selectedBroker == null) ? {}: { brokers: [selectedBrokerName] }),  // Only include brokers if broker is not "ALL"
                        ...((selectedAccount === "all" || selectedAccount === "" || selectedAccount === null || selectedAccount === undefined )  ? {  } : { accountIds : [selectedAccount]}),
                        ...((zenSecId === null || zenSecId?.length === 0) ? {}: { zenSecIds: [zenSecId] }) ,
                    }
                };
                const rowData = await fetchData(apiConfig.THOR_URL+'trade/pnl','POST', header , body );
                if(!targetSymbol){
                    const aggregatedData = rowData.reduce((acc, item) => {
                        const { date, unRealizedYTDPnl, realizedYTDPnl, unRealizedDTDPnl, realizedDTDPnl, unRealizedWTDPnl, realizedWTDPnl,
                            unRealizedMTDPnl, realizedMTDPnl,unRealizedLTDPnl , realizedLTDPnl, dividend } = item;
                    
                        if (!acc[date]) {
                        acc[date] = { date, totalDTDPnl: 0, totalWTDPnl : 0, totalMTDPnl : 0, totalYTDPnl : 0, totalLTDPnl : 0};
                        }
                    
                        acc[date].totalDTDPnl += (unRealizedDTDPnl + realizedDTDPnl);
                        acc[date].totalWTDPnl += (unRealizedWTDPnl + realizedWTDPnl);
                        acc[date].totalMTDPnl += (unRealizedMTDPnl + realizedMTDPnl);
                        acc[date].totalYTDPnl += (unRealizedYTDPnl + realizedYTDPnl);
                        acc[date].totalLTDPnl += (unRealizedLTDPnl + realizedLTDPnl + dividend);
        
                    
                        return acc;
                    }, {});
                    
                    dispatch(setLineChartData(Object.values(aggregatedData)));
                }
                else
                    dispatch(setLineChartData(Array.from(rowData)));
            dispatch(setIsPnlFetching(false));
            }
            catch(error){
                console.log("render error",error);
            }
        }
        if(type !== "")
            fetchDataCall();
      }, [zenSecId,selectedAccount, selectedStrategy, selectedBroker]);

    const chartData = useSelector(state => state.pnlLineChart.lineChartData);
    

    const unRealizedPnlData = chartData?.map((item)=>{
        if(targetSymbol)
            return(
                {
                    unrealizedDTDPnl: item?.unRealizedDTDPnl,
                    unrealizedWTDPnl: item?.unRealizedWTDPnl,
                    unrealizedMTDPnl: item?.unRealizedMTDPnl,
                    unrealizedYTDPnl: item?.unRealizedYTDPnl,
                    unrealizedLTDPnl: item?.unRealizedLTDPnl,
                    realizedDTDPnl: item?.realizedDTDPnl,
                    realizedWTDPnl: item?.realizedWTDPnl,
                    realizedMTDPnl: item?.realizedMTDPnl,
                    realizedYTDPnl: item?.realizedYTDPnl,
                    realizedLTDPnl: item?.realizedLTDPnl,
                    date: item?.date
                }
            );
        return(
            {
                totalDTDPnl : item?.totalDTDPnl,
                totalWTDPnl : item?.totalWTDPnl,
                totalMTDPnl : item?.totalMTDPnl,
                totalYTDPnl : item?.totalYTDPnl,
                totalLTDPnl : item?.totalLTDPnl,
                date : item?.date
            }
        )
    });

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const pnlType = useSelector(state => state.pnlLineChart.pnlType);

    const handlePnlType = ()=>{
        if(pnlType==="realized"){
            dispatch(setPnlType("unrealized"));
        }else{
            dispatch(setPnlType("realized"));
        }
    }
    const CustomTooltip = ({ active, payload, label }) => {
        let month = new Date(payload[0]?.payload?.date).getMonth();
        let dateNumber = new Date(payload[0]?.payload?.date).getDate();
        if (active && payload && payload.length) {
          return (
            <div className="bg-slate-300/80 h-10 w-auto px-2 py-2 rounded-sm">
              <p className="text-sm font-semibold">{`${monthNames[month]?.slice(0,3)} ${dateNumber<10?('0'+dateNumber):dateNumber} :  ${Math.round(Number(payload[0].value)).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    })}`}</p>
            </div>
          );
        }
        return null;
    };
    const CustomLegend = () => {
        return (
            <ul className="flex space-x-4">
                <li className="flex items-center">
                    <span className="block w-10 h-1" style={{ backgroundColor: '#8884D8' }} />
                    <span className="ml-2">{ targetSymbol? `${targetSymbol?.tradingSymbol} ${type} Pnl` : `Total ${type} Pnl`}</span> {/* Custom legend text */}
                </li>
            </ul>
        );
    };

    
    return <PnlLineChartTemplate
        unRealizedPnlData = {unRealizedPnlData}
        handlePnlType = {handlePnlType}
        CustomLegend = {CustomLegend}
        CustomTooltip = {CustomTooltip}
    />;
}
export default PnlLineChartComponent;
