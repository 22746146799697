/*eslint-disable*/
import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
import { AgGridReact } from 'ag-grid-react'; 
import { Button, Spinner, Alert } from "reactstrap";
import { CiEdit } from "react-icons/ci";
import { TbPencilCancel } from "react-icons/tb";
//import OrderForm from '../OrderForm/Field/OrderForm';
import { useGlobalState } from 'components/globalVariable';
import { ShimmerTable } from 'react-shimmer-effects';
import OrderForm from 'components/OrderForm/Field/OrderForm';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TableRefresher from 'components/TableRefreshComponet/TableRefresher';
import OrderStatusCellRender from './OrderStatusCellRender';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdateData } from 'components/OrderForm/Field/orderFormSlice';
import { DraggableComponent } from 'components/OrderForm/Field/draggable';
import { setIsUpdateForm } from 'components/OrderForm/Field/orderFormSlice';
import { selectBroker, selectAccount, selectStrategy } from 'reducers/BrokerSlice.js';
import { setOrderStrategyId } from 'reducers/FilterSlice';
import { getProductTypeandLots } from 'components/PositionsGrid/container/getProductType';
import { apiConfig } from 'apiConfig';
import { useFetchData } from 'utils/customFetch';

function OrderTable () {
    const { fetchData } = useFetchData();
    const [clientId]                    = useGlobalState("clientId")
    const [accountIds]                  = useGlobalState("accountIds")
    const [clientName]                  = useGlobalState('clientName');
    const dispatch = useDispatch();
    const gridRef                       = useRef(); 
    const [rowData, setRowData]         = useState(); 
    const [count, setCount]             = useState(1);
    const [isError, setIsError]         = useState(false);
    const [errorMsg, setErrorMsg]       = useState(null);
    const [refreshTime, setRefreshTime] = useState(new Date().toLocaleTimeString([], { hour12: true }).replace('pm', 'PM'));
    const updateData = useSelector(state => state.orderForm.updateData);   
    const { dates,broker, security,  portfolio, entity, strategy, zenSecIds } = useSelector(state => state.filter);
    const strategyList = useSelector(state => state.filter.strategyList);
    const refreshTriggerCount = useSelector(state => state.orderForm.refreshTriggerCount);
    const secData = useSelector(state => state.broker.securityList)
    const [formOpen, setFormOpen] = useState(false);
    const dateConversion = (val) => {
      const dateObj = new Date(val);
      const year = dateObj.getFullYear();
      const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); 
      const day = ('0' + dateObj.getDate()).slice(-2);
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
  }
    const orderExecutionTimeGetter = (params) => {
      const rowData = params.api.rowModel.rowsToDisplay.map(row => row.data);
      if (rowData) {
          rowData.sort((a, b) => new Date(b.brokerTimestamp).getTime() - new Date(a.brokerTimestamp).getTime());
      }
      return params.value.replace('T', ' ').slice(0, 19);
  };
  
  

  const editCellRenderer = (params) => { 

    const handleIconClick=()=>{
      const rowData = params.data;
      const fetchLatestOrder = async() => {
        const header = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        };
        try{
          const data = await fetchData(`${apiConfig.THOR_URL}order/getLatestOrder?zenOrderId=${rowData?.zenOrderId}`,'POST',header,null);
          dispatch(setIsUpdateForm(true));
          const {securityType,lotSize} = getProductTypeandLots(rowData?.positionCompKey?.zenSecId,secData)
          dispatch(setUpdateData({...data,zenId:rowData?.positionCompKey?.zenSecId,securityType:securityType,lotSize:lotSize}));          
          setFormOpen(true);
        }
        catch(error){
          console.error('There was a problem with the fetch operation:', error);
        }
      }
      fetchLatestOrder();
      //I need to make reques to getorders and find order details for zenorderid
    }
    
    if (params.data.status==="OPEN" || params.data.status==="PENDING" || params.data.status==="TRIGGER_PENDING" ){
      return <CiEdit 
      style={{cursor:"pointer", fontSize: "22px",}}
      onClick={handleIconClick} />;
    }
    else{
      return <TbPencilCancel 
      style={{cursor:"not-allowed", fontSize: "22px",color:' rgb(192, 190, 190)'}}
      />
    }
    
  };

  
  const columnDefs = [
      {
        headerName: "Edit",
        field: "edit",
        headerClass: 'align-left',
        cellClass: 'align-left',
        cellStyle: { textAlign: "left" },
        cellRenderer: editCellRenderer,
        width: 50,
        flex:0,
        editable: false
        
      }
      ,    
      {
        headerName: "Trading Symbol", field: 'tradingSymbol', 
        headerClass: 'align-left',
        cellClass: 'align-left',
        cellStyle:{textAlign:"left"},
        flex:0,
        minWidth:130  
      },
      {
        headerName: "Broker", field: 'positionCompKey.broker', 
        headerClass: 'align-left',
        cellStyle: { textAlign: "left" },
        width:120,
        flex:0     
      },
      {
        headerName: "Strategy Name", field: "strategyLists", 
        headerClass: 'align-left',
        cellStyle: { textAlign: "left" },
        width:180,
        sort: null,
        valueGetter: (params) => {
            const strategyId = params?.data?.positionCompKey?.strategyId; 
            return strategyId && strategyList ? strategyList.filter(str => str.strategy_id === strategyId )[0]?.strategy_name : ''
        }    
    },
      
      {
        headerName: "Status", field: 'status', 
        headerClass: 'align-left',
        cellClass: 'align-left',
        cellStyle:{textAlign:"center"},
        cellRenderer: OrderStatusCellRender,
        
        width:120
      },
      // {
      //   headerName: "Message", field: 'message', 
      //   headerClass: 'align-left',
      //   cellClass: 'align-left',
      //   cellStyle:{textAlign:"left"},
      //   width:100,
      //   flex:1
             
      // },
      {
        headerName: "OrderResponse Time", field: 'brokerTimestamp', minWidth:"190",
        headerClass: 'align-left',
        cellClass: 'align-left',
        valueFormatter:orderExecutionTimeGetter,
        sort: 'desc'
      },   
      {
        headerName: "ZenOrder Id", field: 'zenOrderId', 
        headerClass: "ag-right-aligned-header",
        cellClass: 'align-left',
        flex:0,
        minWidth:100,
        cellStyle:{display: "flex",justifyContent: "flex-end"}    
      },
  ];

  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitCellContents",
    };
  }, []);

  
  const defaultColDef = useMemo(() => ({
    sortable: true,
    editable: true,
    filter: true,
  }), []);


  var cellClickedListener = ( ) => {
    setCount(count + 1);
  };

  const [isFetching,setIsFetching] = useState(true);
  const {selectedStrategy, selectedBroker, selectedBrokerName,selectedAccount} =  useSelector(state => state.clientSelection)
  useEffect(() => {
    setIsFetching(true)
    const body = {
      positionCompKeyFilter: {
          clientId: clientId,
          ...((selectedAccount === "all" || selectedAccount === "" || selectedAccount === null || selectedAccount === undefined )  ? {  } : { accountIds : [selectedAccount]}),
          ...((zenSecIds === null || zenSecIds === undefined || zenSecIds.length === 0) ? {} : {zenSecIds : zenSecIds}),
          // Conditionally include strategyIds only if strategy is not empty
          ...((selectedStrategy === "all" || selectedStrategy === "" || selectedStrategy === null || selectedStrategy === undefined )  ? {  } : { strategyIds : [selectedStrategy]}),
          ...((selectedBroker === "all" || selectedBroker === "" || selectedBroker === null || selectedBroker === undefined) ? {}: { brokers: [selectedBrokerName] })  // Only include brokers if broker is not "ALL"
      }
    };
    const fetchDataCall = async () => {
      try {
        const header = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        };
        const rowData = await fetchData(apiConfig.THOR_URL+'order/getZenOrderState', 'POST',header, body);
        
        setRowData(Array.from(rowData));
        setRefreshTime(new Date().toLocaleTimeString([], { hour12: true }));
        setTimeout(()=>setIsFetching(false),750);
        setIsError(false);
       
      } 
      catch (error) {
        console.error('Error fetching data:', error);
        setIsError(true);
        setErrorMsg(error)
      }
    };
    if(dates[1])
      fetchDataCall();


    const intervalId = setInterval(fetchDataCall, 60000);
    //setIntervel cleaning process
    return(()=>{
      clearInterval(intervalId);
  }) 

  }, [dates,refreshTriggerCount,count,clientId,zenSecIds, selectedAccount,selectedStrategy, selectedBroker, selectedBrokerName]);

  const buttonListener = useCallback( e => {
    gridRef.current.api.deselectAll();
  }, []);

  const containerStyle = useMemo(() => ({  height: 'auto', width : '100%' , overflow: 'hidden' }), []);
  const gridStyle = useMemo(() => ({ height: '580px', width : '1060px' }), []);


    return (
    <>
      <TableRefresher cellClickedListener={cellClickedListener} showSpinner={isFetching}/>
    {
    formOpen && <div style={{
       backgroundColor: 'rgba(0,0,0,0.6)',
       position: 'fixed',
       height: '100%',
       width: '100%',
       top: 0,
       left: 0,
       display: 'flex',
       zIndex: 1

      }}>
      <DraggableComponent>
        <OrderForm 
          trade={updateData?.transactionType}
          setFormOpen = {(value) => setFormOpen(!value)}
          hideForm = {() => setFormOpen(false)}
        />
      </DraggableComponent>      
      </div>}

    <div style={gridStyle} className="ag-theme-alpine">
        {
            isError ? 
            <Alert color="danger">
             {errorMsg}
            </Alert>
            : ''
        }
        {
           isFetching?
           <ShimmerTable row={4} col={4} />
           :
            <AgGridReact
            ref={gridRef} 
            rowData={rowData} 
            columnDefs={columnDefs} 
            defaultColDef={defaultColDef}           
            
            paginationPageSize={10} 
            animateRows={true} 
            autoSizeStrategy={autoSizeStrategy}
            //domLayout='autoHeight'
            />
        }
      </div> 
    </>
  );
};

export default OrderTable;
