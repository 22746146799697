/* eslint-disable */
import OrderTable from "../components/OrderTable/OrderTable";
import React from "react";
import OrderFormContainer from "components/OrderForm/Field/OrderFormContainer";
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Toast, ToastContainer, Button } from 'react-bootstrap';
import './Order.css'
import { useGlobalState } from "components/globalVariable";
import { useDispatch, useSelector } from "react-redux";
import { setShowOrderResponse } from "components/OrderForm/Field/orderFormSlice";
import { setOrderMessage } from "components/OrderForm/Field/orderFormSlice";
import { setOrderStatus } from "components/OrderForm/Field/orderFormSlice";
import { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import Toasts from "components/Toasts/Toasts";
import UnAuthorizedPage from "components/UnAuthorizedPage";

function OrdersGrid() {
  const [clientId] = useGlobalState("clientId");
  const dispatch = useDispatch();
  const orderMessage = useSelector(state => state.orderForm.orderMessage);
  const orderStatus = useSelector(state => state.orderForm.orderStatus);
  const showOrderResponse = useSelector(state => state.orderForm.showOrderResponse);
  const isAuthorized = useSelector(state => state.protectedRoute.isAuthorized);

  const options = [
    { label: "Buy", value: "BUY", color: "blue" },
    { label: "Sell", value: "SELL", color: "black" },
  ];
  useEffect(() => {
    dispatch(setShowOrderResponse(false));
    return undefined;
  },[clientId])
  return (
    
    <>
      <Row>
        <Col xs="auto" width={64}>
          <Card>
            <CardBody>
              <OrderFormContainer options={options} />
            </CardBody>
          </Card>
        </Col>
        <Col md="auto">
          <Card>
            <OrderTable />
          </Card>
        </Col>
      </Row>
      <Toasts />
    </> 
    
  );
}

export default OrdersGrid;
