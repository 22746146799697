/* eslint-disable */
import { useEffect, useState } from "react";
import ChartPage from './ChartPage'
import getData from "./CypherResult";
import getEODPrice from "./EodPrice";
import { getIndexEODPrice } from "./EodPrice";
import { useDispatch, useSelector } from "react-redux";
import { setShowChart } from "../../GraphTemplate/container/GraphTemplateSlice";
import { getIndexData } from "./CypherResult";
import { setChartData, setChartIndexData } from "../../CytoscapeGraph/container/MainTemplateSlice";
import { useFetchData } from "utils/customFetch";

const ChartTemplate = () => {
  const dispatch = useDispatch();
  const { fetchData } = useFetchData();
  const showChart = useSelector(state => state.graphTemplate.showChart)
  const targetNode = useSelector(state => state.graphTemplate.targetNode)
  const chartData = useSelector(state => state.mainTemplate.chartData)
  const [data, setData] = useState([])
  const [yAxisData, setYAxisData] = useState([])
  const chartIndexData = useSelector(state => state.mainTemplate.chartIndexData)
  const popoverIndexLabels = useSelector(state => state.mainTemplate.popoverIndexLabels)
  const isIndex = useSelector(state => state.mainTemplate.isIndex)
  const popoverNode = useSelector(state => state.graphTemplate.popoverNode)

  document.addEventListener('keydown', function(event){
    if(event.key === 'Escape')
    {
      dispatch(setShowChart(false))
    }
  })
  const options = {
      chart: {
        zoomType: 'xy'
      },
      title : {
        text : targetNode
      },
      xAxis : {
        // type : 'datetime',
        title : {
          text : 'Trade Date'
        },
        crosshair: true
      },
      yAxis: yAxisData,
      zIndex : 0,
      series: data,
      credits: {
        enabled: false
      },
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'middle'
    },
    rangeSelector: {
      enabled: false
    }
  };
  
    useEffect(() => {
      if(!isIndex)
        getData(fetchData, dispatch,targetNode,setChartData)
        getIndexData(fetchData, dispatch, setChartIndexData, popoverIndexLabels)
        
    }, [targetNode]);

    useEffect(() => {
      if(!isIndex && Object.keys(chartData).length !=0 && Object.keys(chartIndexData).length !=0)
        getEODPrice(targetNode, popoverIndexLabels, chartData, chartIndexData, setData, isIndex, setYAxisData)
      else if(Object.keys(chartIndexData).length !=0)
        getEODPrice(targetNode, popoverIndexLabels, chartData, chartIndexData, setData, isIndex, setYAxisData)
    },[chartData,chartIndexData])
    return ( 
          <ChartPage
            targetNode={targetNode}
            options = {options}
            data = {data}
          />
    )
}

export default ChartTemplate;